<template>
  <div class="container">
    <h1>Vorabinformationen zu Ihrem Fall</h1>
    <p>
      Herzlichen Dank, dass Sie uns Ihr Vertrauen schenken. Um Ihr Anliegen
      bestmöglich bearbeiten zu können benötigen wir einige Informationen von
      Ihnen. Bitte lesen Sie den folgenden Text aufmerksam durch und füllen die
      Felder am Ende der Seite aus.
    </p>

    <h2>Kosten</h2>
    <p>
      Falls Sie meine Kosten nicht selbst tragen, kümmern Sie sich bitte zuvor
      um einen Beratungshilfeschein oder die Deckungszusage Ihrer
      Rechtsschutzversicherung.
      <b>Für die erste Beratung fallen unabhängig vom Streitwert Kosten von
        mindestens 15 € und höchstens 226,10 € an.</b>
      Die Anwaltsvergütung ist gesetzlich im
      <a href="https://www.gesetze-im-internet.de/rvg/">Rechtsanwaltsvergütungsgesetz</a>
      geregelt, Anknüpfungspunkt für die Höhe der Vergütung ist der Streitwert
      (Höhe des streitigen Betrages).
    </p>

    <h2>Datenschutz</h2>
    <p>
      Um unseren Anwaltsvertrag erfüllen zu können, erhebe ich Ihre Personalien
      sowie alle zur Fallbearbeitung notwendigen Informationen. Die Weitergabe
      erfolgt nur im Rahmen der Mandatsbearbeitung sowie zur Abwicklung der sich
      aus dem Mandatsverhältnis zwischen uns ergebenden Ansprüche. Ihre Daten
      werden sechs Jahre nach Ablauf des Kalenderjahres, in dem der
      Anwaltsvertrag endet, gelöscht, soweit sich nicht aus dem Steuerrecht
      längere Aufbewahrungsfristen (zehn Jahre) ergeben. Sie haben das Recht,
      Auskunft über Ihre gespeicherten Daten zu erhalten und diese
      erforderlichenfalls zu berichtigen. Falls Sie der Auffassung sein sollten,
      dass die Datenverarbeitung nicht rechtmäßig erfolgt, können Sie sich bei
      der zuständigen Aufsichtsbehörde, dem bayerischen Landesbeauftragen für
      den Datenschutz, beschweren.
    </p>

    <h2>Ihre Personalien</h2>
    <form id="app" @submit="submitForm" action="#">
      <div class="form-row">
        <div class="form-group col-md">
          <label for="clientFirstname">Vorname</label>
          <input
            class="form-control"
            id="clientFirstname"
            v-model="clientFirstname"
            type="text"
            name="clientFirstname"
            required
          />
          <div class="invalid-feedback">Pflichtangabe</div>
        </div>
        <div class="form-group col-md">
          <label for="clientLastname">Nachname</label>
          <input
            class="form-control"
            id="clientLastname"
            v-model="clientLastname"
            type="text"
            name="clientLastname"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md">
          <label for="clientAddressLine1">Straße und Hausnummer</label>
          <input
            class="form-control"
            id="clientAddressLine1"
            v-model="clientAddressLine1"
            type="text"
            name="clientAddressLine1"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="clientZip">Postleitzahl</label>
          <input
            class="form-control"
            id="clientZip"
            v-model="clientZip"
            type="text"
            name="clientZip"
            required
          />
        </div>
        <div class="form-group col-md-5">
          <label for="clientCity">Ort</label>
          <input
            class="form-control"
            id="clientCity"
            v-model="clientCity"
            type="text"
            name="clientCity"
            required
          />
        </div>
        <div class="form-group col-md-5">
          <label for="clientCountry">Land</label>
          <input
            class="form-control"
            id="clientCountry"
            v-model="clientCountry"
            type="text"
            name="clientCountry"
            value="Deutschland"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md">
          <label for="clientTelLandline">Festnetznummer (mit Vorwahl)</label>
          <input
            class="form-control"
            id="clientTelLandline"
            v-model="clientTelLandline"
            type="text"
            name="clientTelLandline"
          />
        </div>
        <div class="form-group col-md">
          <label for="clientTelMobile">Handynummer (mit Vorwahl)</label>
          <input
            class="form-control"
            id="clientTelMobile"
            v-model="clientTelMobile"
            type="text"
            name="clientTelMobile"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="clientEmail">E-Mail Adresse</label>
        <input
          class="form-control"
          id="clientEmail"
          v-model="clientEmail"
          type="email"
          name="clientEmail"
        />
      </div>

      <h2>Ihr Gegner (optional)</h2>
      <p>
        Lassen Sie die folgenden Felder frei falls Sie Ihren Gegner nicht kennen
        oder es keinen Gegner gibt.
      </p>

      <div class="form-row">
        <div class="form-group col-md">
          <label for="opponentFirstname">Vorname</label>
          <input
            class="form-control"
            id="opponentFirstname"
            v-model="opponentFirstname"
            type="text"
            name="opponentFirstname"
          />
        </div>
        <div class="form-group col-md">
          <label for="opponentLastname">Nachname</label>
          <input
            class="form-control"
            id="opponentLastname"
            v-model="opponentLastname"
            type="text"
            name="opponentLastname"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md">
          <label for="opponentAddressLine1">Straße und Hausnummer</label>
          <input
            class="form-control"
            id="opponentAddressLine1"
            v-model="opponentAddressLine1"
            type="text"
            name="opponentAddressLine1"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="opponentZip">Postleitzahl</label>
          <input
            class="form-control"
            id="opponentZip"
            v-model="opponentZip"
            type="text"
            name="opponentZip"
          />
        </div>
        <div class="form-group col-md-5">
          <label for="opponentCity">Ort</label>
          <input
            class="form-control"
            id="opponentCity"
            v-model="opponentCity"
            type="text"
            name="opponentCity"
          />
        </div>
        <div class="form-group col-md-5">
          <label for="opponentCountry">Land</label>
          <input
            class="form-control"
            id="opponentCountry"
            v-model="opponentCountry"
            type="text"
            name="opponentCountry"
            value="Deutschland"
          />
        </div>
      </div>
      <button
        class="btn btn-success btn-lg font-bold"
        :disabled="processing"
        type="submit"
      >
        Ja, ich habe die Informationen zu Kosten und Datenschutz gelesen und
        stimme zu
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

const API_URL = "/api.php";

export default {
  name: "Register",

  data() {
    return {
      processing: false,
      clientFirstname: "",
      clientLastname: "",
      clientAddressLine1: "",
      clientZip: "",
      clientCity: "",
      clientCountry: "Deutschland",
      clientTelLandline: "",
      clientTelMobile: "",
      clientEmail: "",
      opponentFirstname: "",
      opponentLastname: "",
      opponentAddressLine1: "",
      opponentZip: "",
      opponentCity: "",
      opponentCountry: "Deutschland",
    };
  },

  methods: {
    submitForm: function(e) {
      this.processing = true;
      axios.post(API_URL, this.$data).then(() =>
        this.$router.push({
          name: "Home",
          params: {
            msg:
              "Herzlichen Dank, das Formular mit Ihren Vorabinformationen wurde erfolgreich abgeschickt.",
            msg_header: "Formular erfolgreich abgeschickt",
          },
        })
      );

      e.preventDefault();
    },
  },
};
</script>
